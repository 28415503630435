import * as React from 'react';

import { Container, Img } from './styles';

import Resource from '../Resource';

const CaseStudy = ({ caseStudy, ...props }) => {
  return (
    <Container {...props}>
      <Img
        src={
          caseStudy?.attributes?.media?.data?.[0]?.attributes?.formats?.medium?.url ||
          caseStudy?.attributes?.media?.data?.[0]?.attributes?.url
        }
        loading="lazy"
      />
      <Resource
        full
        type={caseStudy?.attributes?.library_types?.data?.[0]?.attributes?.name}
        resource={caseStudy?.attributes}
      />
    </Container>
  );
};

export default CaseStudy;
