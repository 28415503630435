import * as React from 'react';
import loadable from '@loadable/component';

import { COLORS } from '../utils/theme';
import SEO from '../components/SEO';
import Background from '../components/Background';
import Header from '../components/Header';
import HeaderTitle from '../components/HeaderTitle';
import CaseStudiesAndResources from '../components/Tools/CaseStudiesAndResources';
import Library from '../components/Tools/Library';
import DataWorld from '../components/Tools/DataWorld';
import { SELECTION_MODE } from '../components/Filters/constants';
import qs from 'qs';

import useScrollPosition from '@react-hook/window-scroll';

import { Logo, MenuButton, SectionContainer } from '../components/HomeHeader/styles';

import { contentFetch } from '../lib/content-fetcher';
import { PAGES_TYPES } from '../utils/constants';

const Footer = loadable(() => import('../components/Footer'));

const transformSortOtherLast = (d) =>
  d.data
    .sort((item) => {
      return item?.attributes?.name === 'Other' ? 10 : null;
    })
    .map((item) => ({
      ...item.attributes,
      id: item.id,
    }));

const LibraryPage = () => {
  const scrollY = useScrollPosition(60);
  const hide = scrollY < 120;

  const [menuIsShown, showMenu] = React.useState(false);
  const [caseStudies, setCaseStudies] = React.useState([]);
  const [libraryTypes, setLibraryTypes] = React.useState([]);
  const [impactTopics, setImpactTopics] = React.useState([]);

  const getCaseStudies = (filters = {}) => {
    const caseStudiesQuery = qs.stringify(
      {
        filters: filters,
        populate: {
          library_types: {
            fields: ['name'],
          },
          media: {
            fields: ['url', 'formats'],
          },
        },
        sort: ['createdAt:desc'],
        fields: ['title', 'description', 'openinnewwindow', 'link', 'linktext'],
      },
      {
        encodeValuesOnly: true,
      },
    );

    contentFetch({
      pathname: `/case-studies-and-resources?${caseStudiesQuery}`,
      setState: setCaseStudies,
      transform: (d) => d?.data,
    });
  };

  React.useEffect(() => {
    contentFetch({
      pathname: '/library-types?sort=name',
      setState: setLibraryTypes,
      transform: transformSortOtherLast,
    });
    contentFetch({
      pathname: '/tags?sort=name',
      setState: setImpactTopics,
      transform: (d) =>
        d?.data.map((item) => ({
          ...item.attributes,
          id: item.id,
        })),
    });
    getCaseStudies();
  }, []);

  return (
    <Background color={COLORS.BACKGROUND_LIGHT}>
      <SEO title="Library"></SEO>
      <Header menuIsShown={menuIsShown} showMenu={showMenu} />
      <SectionContainer>
        <Logo visible={!hide} />
        <MenuButton visible={!hide} menuIsShown={menuIsShown} />
        <HeaderTitle menuIsShown={menuIsShown} type={PAGES_TYPES.LIBRARY} />
      </SectionContainer>
      <Library
        menuIsShown={menuIsShown}
        type="CASE_STUDY"
        filters={[
          {
            name: `Resource Type`,
            id: 'library_types',
            values: libraryTypes,
            selection: SELECTION_MODE.SINGLE_CHOICE,
          },
          {
            name: `Impact Topic`,
            id: 'impact_topics',
            values: impactTopics,
            selection: SELECTION_MODE.SINGLE_CHOICE,
          },
        ]}
        applyFilters={getCaseStudies}
      />

      <CaseStudiesAndResources menuIsShown={menuIsShown} caseStudies={caseStudies} />
      <DataWorld menuIsShown={menuIsShown} />
      <Footer />
    </Background>
  );
};

export default LibraryPage;
