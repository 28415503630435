import * as React from 'react';
import { contentFetch } from '../../../lib/content-fetcher';
import Popup from 'reactjs-popup';
import qs from 'qs';

import {
  Container,
  DataWorld,
  ImageContainer,
  Title,
  Desc,
  Subdesc,
  MoreLoadRow,
  Plus,
  MoreLoadText,
  PopupContainer,
  PopupClose,
  PopupTitle,
  PopupContent,
} from './styles';

import { Logo, MenuButton, SectionContainer } from '../../HomeHeader/styles';

import { addTargetBlankToLinks } from '../../../utils/html';

import Filters from '../../Filters';

const tranformFilters = (result) =>
  result?.data?.length
    ? result?.data?.map((item) => ({ id: item.id, text: item.attributes?.name }))
    : [];

const DATA_WORLD_MORE = 3;

const DataWorlds = ({ menuIsShown }) => {
  const [dataWorlds, setDataWorlds] = React.useState([]);
  const [filters, setFilters] = React.useState([]);
  const [filterSelected, setFilterSelected] = React.useState(null);
  const [loadMoreDataWorld, setLoadMoreDataWorld] = React.useState(DATA_WORLD_MORE);
  const [modalData, setModalData] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const makePopupTrigger = React.useCallback(
    ({ imageUrl, title } = {}) =>
      () => {
        setModalData({
          imageUrl,
          title,
        });
        openModal();
      },
    [setModalData],
  );

  const getDataWorlds = (selectedIds = []) => {
    const dataWorldsQuery = qs.stringify(
      {
        filters: {
          tags: {
            id: selectedIds,
          },
        },
        populate: '*',
      },
      {
        encodeValuesOnly: true,
      },
    );
    contentFetch({
      pathname: `/data-worlds?${dataWorldsQuery}`,
      setState: setDataWorlds,
      transform: (d) => d.data,
    });
  };

  React.useLayoutEffect(() => {
    const dataWorldContainer = document.getElementById('dataworld-container');
    const links = dataWorldContainer.querySelectorAll('a');
    addTargetBlankToLinks(links);
  }, [dataWorlds]);

  React.useEffect(() => {
    contentFetch({
      pathname: '/tags?sort=name',
      setState: setFilters,
      transform: tranformFilters,
    });
    getDataWorlds();
  }, []);

  React.useEffect(() => {
    if (filterSelected) {
      getDataWorlds(filterSelected);
    } else {
      getDataWorlds();
    }
  }, [filterSelected]);

  const handleLoadMore = () => setLoadMoreDataWorld((prevSlice) => prevSlice + DATA_WORLD_MORE);

  const dataWorldsToShow = dataWorlds?.length ? dataWorlds.slice(0, loadMoreDataWorld) : [];

  const hasMoreDataWorlds = dataWorlds.length > loadMoreDataWorld;

  return (
    <SectionContainer>
      <Logo visible />
      <MenuButton visible menuIsShown={menuIsShown} />
      <Filters title="Data world" filters={filters} apply={setFilterSelected} />
      <Container id="dataworld-container">
        {dataWorldsToShow.map((dataWorld) => (
          <DataWorld {...dataWorld.attributes}>
            <ImageContainer
              style={{
                backgroundImage: `url(${
                  dataWorld.attributes.image?.data?.[0]?.attributes?.formats?.medium?.url ||
                  dataWorld.attributes.image?.data?.[0]?.attributes?.url
                })`,
              }}
              onClick={makePopupTrigger({
                imageUrl: dataWorld.attributes.image?.data?.[0]?.attributes?.url,
                title: dataWorld?.attributes?.title,
              })}
              {...dataWorld.attributes}
            >
              <span>View image</span>
            </ImageContainer>
            <Title>{dataWorld?.attributes?.title}</Title>
            {/* <Tag>{dataWorld.tags}</Tag> */}
            <Desc dangerouslySetInnerHTML={{ __html: dataWorld?.attributes?.description }} />
            <Subdesc dangerouslySetInnerHTML={{ __html: dataWorld?.attributes?.subdescription }} />
          </DataWorld>
        ))}
      </Container>
      {hasMoreDataWorlds && (
        <MoreLoadRow onClick={handleLoadMore}>
          <Plus />
          <MoreLoadText>Load more</MoreLoadText>
        </MoreLoadRow>
      )}
      {modalData && (
        <Popup open={isModalOpen} onClose={closeModal} modal nested>
          <PopupContainer>
            <PopupClose onClick={closeModal}>&times;</PopupClose>
            <PopupTitle>{modalData.title}</PopupTitle>
            <PopupContent>
              <img src={modalData.imageUrl} />
            </PopupContent>
          </PopupContainer>
        </Popup>
      )}
    </SectionContainer>
  );
};

export default DataWorlds;
