import * as React from 'react';

import { Container, Row, MoreLoadRow, Plus, MoreLoadText } from './styles';

import { Logo, MenuButton } from '../../HomeHeader/styles';

import CaseStudy from '../CaseStudy';

const SLICE_STEP = 3;

const CaseStudiesAndResources = ({ caseStudies = [], menuIsShown }) => {
  const [loadMoreSlice, setLoadMoreSlice] = React.useState(SLICE_STEP);

  const handleLoadMore = () => setLoadMoreSlice((prevSlice) => prevSlice + SLICE_STEP);

  const slicedCaseStudies = caseStudies?.length ? caseStudies.slice(0, loadMoreSlice) : [];

  const hasMoreCaseStudies = caseStudies.length > loadMoreSlice;

  return (
    <Container>
      <Logo visible />
      <MenuButton visible menuIsShown={menuIsShown} />
      <Row>
        {slicedCaseStudies.map((item, index) => (
          <CaseStudy key={index} caseStudy={item}></CaseStudy>
        ))}
      </Row>
      {hasMoreCaseStudies && (
        <MoreLoadRow onClick={handleLoadMore}>
          <Plus />
          <MoreLoadText>Load more resources</MoreLoadText>
        </MoreLoadRow>
      )}
    </Container>
  );
};

export default CaseStudiesAndResources;
